





























import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import Gate from '@/permissions/Gate';
import {PolicyMap} from '@/permissions/types/PolicyMap';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import {VersionTaskState} from '@/tasks/types/VersionTaskState';

export default defineComponent({
  name: 'TorontoCHMB41HQuiz3Question1',
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<VersionTaskState>,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        value: null as null | string,
      },
      imagePrefix: '/img/assignments/UofT/CHMB41H/quiz-3/question-1/q3q1',
    };
  },
  computed: {
    seed(): number {
      return (this.$gate as Gate<PolicyMap>).user?.id ?? 1;
    },
    version(): number {
      return this.taskState.getValueByName('version')?.content?.value ?? 1;
    },
    choices(): string[] {
      return seededShuffle(['A', 'B', 'C', 'D'], this.seed);
    },
    image(): string {
      return this.imagePrefix + `v${this.version}.png`;
    },
    images(): string[] {
      return this.choices.map((l: string) => this.imagePrefix + '-choice' + l + '.png');
    },
  },
});
